import { Box } from "@chakra-ui/react"
import React from "react"
import Seo from "../components/seo"
import { useDelayedAnalyticsEventOnLand } from "../hooks/analytics"
import privacy from "../static/privacy.pdf"
import { AnalyticsEvents } from "../services/types"

const PrivacyPage = () => {
  useDelayedAnalyticsEventOnLand(AnalyticsEvents.privacy_policy_landed)
  return (
    <Box h="100vh" w="100vw">
      <Seo title="Privacy Policy" />
      <embed src={privacy} height="100%" width="100%" />
    </Box>
  )
}

export default PrivacyPage
